import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'lumiron-section-summary',
  templateUrl: 'section-summary.component.html',
  styleUrl: './section-summary.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class SectionSummaryComponent {}
